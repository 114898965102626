import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   *
   *     Errors messages and codes mapped to
   *     fields or non fields errors.
   *     Example:
   *     {
   *         field_name: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         other_field: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         nonFieldErrors: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ]
   *     }
   *
   */
  ExpectedErrorType: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AddFoodSafetyFileMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type AddMiscellaneousFileMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type AddPackoutFileMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type AddReturnFileMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type AddStaffMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type AddTokenMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type Block = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  poolIds: PoolIdConnection;
};


export type BlockPoolIdsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type BlockConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BlockEdge>>;
};

/** A Relay edge containing a `Block` and its cursor. */
export type BlockEdge = {
  /** The item at the end of the edge */
  node: Maybe<Block>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BlockSearch = {
  pageInfo: Maybe<PaginationInfo>;
  blocks: Maybe<Array<Maybe<Block>>>;
};

export type CachedCherryCropYear = {
  id: Scalars['ID'];
  grower: Grower;
  data: Maybe<Scalars['JSONString']>;
};

export type CachedCropYear = {
  cherry: Maybe<Scalars['String']>;
  apple: Maybe<Scalars['String']>;
  pear: Maybe<Scalars['String']>;
};

export type CachedGraphData = {
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  graph: Graph;
  grower: Grower;
  pool: Maybe<PoolId>;
  block: Maybe<Block>;
  stats: Maybe<Scalars['JSONString']>;
  data: Maybe<Scalars['JSONString']>;
  errored: Scalars['Boolean'];
};

export type Commodity = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  varieties: VarietyConnection;
};


export type CommodityVarietiesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type CommodityConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommodityEdge>>;
};

/** A Relay edge containing a `Commodity` and its cursor. */
export type CommodityEdge = {
  /** The item at the end of the edge */
  node: Maybe<Commodity>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};


export type DeleteTokenMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export enum DocType {
  AuditCertificate = 'AUDIT_CERTIFICATE',
  AuditReport = 'AUDIT_REPORT',
  SystemsApproach = 'SYSTEMS_APPROACH'
}


export type File = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Maybe<FileTypes>;
  docType: Maybe<Scalars['String']>;
  block: Maybe<Block>;
  cropYear: Maybe<FileCropYear>;
  method: FileMethod;
  variety: Maybe<Variety>;
  poolIds: PoolIdConnection;
  path: Scalars['String'];
  allGrowers: Scalars['Boolean'];
  grower: Maybe<Grower>;
};


export type FilePoolIdsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

/** An enumeration. */
export enum FileCropYear {
  /** 2033 */
  A_2033 = 'A_2033',
  /** 2032 */
  A_2032 = 'A_2032',
  /** 2031 */
  A_2031 = 'A_2031',
  /** 2030 */
  A_2030 = 'A_2030',
  /** 2029 */
  A_2029 = 'A_2029',
  /** 2028 */
  A_2028 = 'A_2028',
  /** 2027 */
  A_2027 = 'A_2027',
  /** 2026 */
  A_2026 = 'A_2026',
  /** 2025 */
  A_2025 = 'A_2025',
  /** 2024 */
  A_2024 = 'A_2024',
  /** 2023 */
  A_2023 = 'A_2023',
  /** 2022 */
  A_2022 = 'A_2022',
  /** 2021 */
  A_2021 = 'A_2021',
  /** 2020 */
  A_2020 = 'A_2020',
  /** 2019 */
  A_2019 = 'A_2019',
  /** 2018 */
  A_2018 = 'A_2018',
  /** 2017 */
  A_2017 = 'A_2017',
  /** 2016 */
  A_2016 = 'A_2016',
  /** 2015 */
  A_2015 = 'A_2015',
  /** 2014 */
  A_2014 = 'A_2014'
}

/** An enumeration. */
export enum FileMethod {
  /** Conventional */
  Conventional = 'CONVENTIONAL',
  /** Organic */
  Organic = 'ORGANIC'
}

export type FileSearch = {
  pageInfo: Maybe<PaginationInfo>;
  files: Maybe<Array<Maybe<File>>>;
};

export type FileTypes = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  accepts: Array<Maybe<Scalars['String']>>;
  requiresStaff: Scalars['Boolean'];
  filterAllgrowers: Scalars['Boolean'];
  filterDoctype: Scalars['Boolean'];
  filterBlock: Scalars['Boolean'];
  filterCropyear: Scalars['Boolean'];
  filterMethod: Scalars['Boolean'];
  filterVariety: Scalars['Boolean'];
  filterPoolids: Scalars['Boolean'];
};

export type FileTypesConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FileTypesEdge>>;
};

/** A Relay edge containing a `FileTypes` and its cursor. */
export type FileTypesEdge = {
  /** The item at the end of the edge */
  node: Maybe<FileTypes>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type FileUser = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Maybe<FileTypes>;
  docType: Maybe<Scalars['String']>;
  block: Maybe<Block>;
  cropYear: Maybe<FileCropYear>;
  method: FileMethod;
  variety: Maybe<Variety>;
  poolIds: PoolIdConnection;
  path: Scalars['String'];
  allGrowers: Scalars['Boolean'];
  grower: Maybe<Grower>;
};


export type FileUserPoolIdsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type FileUserSearch = {
  pageInfo: Maybe<PaginationInfo>;
  files: Maybe<Array<Maybe<FileUser>>>;
};


export type Graph = Node & {
  name: Scalars['String'];
  machine: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type GraphConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraphEdge>>;
};

/** A Relay edge containing a `Graph` and its cursor. */
export type GraphEdge = {
  /** The item at the end of the edge */
  node: Maybe<Graph>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Grower = Node & {
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  internalId: Scalars['String'];
  blocks: BlockConnection;
  users: Maybe<Array<Maybe<Profile>>>;
  messageAlert: Scalars['String'];
  messageFoodSafety: Scalars['String'];
  files: Maybe<FileSearch>;
  /** The ID of the object. */
  id: Scalars['ID'];
  invited: Maybe<Array<Maybe<ProfileToUser>>>;
  commodities: Maybe<Array<Maybe<Scalars['String']>>>;
  formattedName: Maybe<Scalars['String']>;
};


export type GrowerBlocksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type GrowerUsersArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
};


export type GrowerFilesArgs = {
  fileType?: Maybe<Scalars['ID']>;
  docType?: Maybe<Scalars['String']>;
  poolId?: Maybe<Scalars['ID']>;
  block?: Maybe<Scalars['ID']>;
  cropYear?: Maybe<Scalars['Int']>;
  method?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type GrowerAddUserMutation = {
  success: Scalars['Boolean'];
  noAccess: Maybe<Array<Maybe<Scalars['String']>>>;
  invitationSent: Maybe<Array<Maybe<Scalars['String']>>>;
  added: Maybe<Array<Maybe<Scalars['String']>>>;
  alreadyAdded: Maybe<Array<Maybe<Scalars['String']>>>;
  message: Maybe<Scalars['String']>;
};

export type GrowerProfileSchema = Node & {
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  internalId: Scalars['String'];
  blocks: BlockConnection;
  users: Maybe<Array<Maybe<Profile>>>;
  messageAlert: Scalars['String'];
  messageFoodSafety: Scalars['String'];
  files: Maybe<FileUserSearch>;
  /** The ID of the object. */
  id: Scalars['ID'];
  commodities: Maybe<Array<Maybe<Scalars['String']>>>;
  formattedName: Maybe<Scalars['String']>;
};


export type GrowerProfileSchemaBlocksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type GrowerProfileSchemaUsersArgs = {
  search?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type GrowerProfileSchemaFilesArgs = {
  fileType?: Maybe<Scalars['ID']>;
  docType?: Maybe<Scalars['String']>;
  poolId?: Maybe<Scalars['ID']>;
  block?: Maybe<Scalars['ID']>;
  cropYear?: Maybe<Scalars['Int']>;
  method?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type GrowerRemoveUserMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type GrowerSearch = {
  pageInfo: Maybe<PaginationInfo>;
  growers: Maybe<Array<Maybe<Grower>>>;
};

export type GrowerUpdateMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type InvalidateTokenMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};


export type Mutation = {
  profileSignup: Maybe<ProfileSignup>;
  profileUpdate: Maybe<ProfileUpdateMutation>;
  profileTargetUpdate: Maybe<TargetProfileUpdateMutation>;
  userDelete: Maybe<UserDelete>;
  userReinvite: Maybe<UserReinvite>;
  settingUpdate: Maybe<SettingUpdateMutation>;
  fileDelete: Maybe<RemoveFileMutation>;
  growerUpdate: Maybe<GrowerUpdateMutation>;
  growerAddUser: Maybe<GrowerAddUserMutation>;
  growerRemoveUser: Maybe<GrowerRemoveUserMutation>;
  addMiscellaneousFile: Maybe<AddMiscellaneousFileMutation>;
  addReturnFile: Maybe<AddReturnFileMutation>;
  addFoodSafetyFile: Maybe<AddFoodSafetyFileMutation>;
  addPackoutFile: Maybe<AddPackoutFileMutation>;
  addStaff: Maybe<AddStaffMutation>;
  removeStaff: Maybe<RemoveStaffMutation>;
  updateStaff: Maybe<UpdateStaffMutation>;
  addToken: Maybe<AddTokenMutation>;
  deleteToken: Maybe<DeleteTokenMutation>;
  invalidateToken: Maybe<InvalidateTokenMutation>;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset: Maybe<PasswordReset>;
  /**
   * Change account password when user knows the old password.
   *
   * A new token and refresh token are sent. User must be verified.
   */
  passwordChange: Maybe<PasswordChange>;
  /**
   * Send password reset email.
   *
   * For non verified users, send an activation
   * email instead.
   *
   * Accepts both primary and secondary email.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  sendPasswordResetEmail: Maybe<SendPasswordResetEmail>;
  tokenAuth: Maybe<ObtainJsonWebToken>;
  verifyToken: Maybe<TokenVerifyMutation>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken: Maybe<RefreshToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  revokeToken: Maybe<RevokeToken>;
};


export type MutationProfileSignupArgs = {
  email: Scalars['String'];
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat: Scalars['String'];
  phone: Scalars['String'];
  receiveEmail: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
  token: Scalars['String'];
};


export type MutationProfileUpdateArgs = {
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receiveEmail?: Maybe<Scalars['Boolean']>;
  receiveSms?: Maybe<Scalars['Boolean']>;
};


export type MutationProfileTargetUpdateArgs = {
  email?: Maybe<Scalars['String']>;
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receiveEmail?: Maybe<Scalars['Boolean']>;
  receiveSms?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
  userType?: Maybe<ProfileTypeCreate>;
};


export type MutationUserDeleteArgs = {
  userid: Scalars['ID'];
};


export type MutationUserReinviteArgs = {
  userid: Scalars['ID'];
};


export type MutationSettingUpdateArgs = {
  name?: Maybe<SettingType>;
  value: Scalars['String'];
};


export type MutationFileDeleteArgs = {
  fileId: Scalars['ID'];
};


export type MutationGrowerUpdateArgs = {
  grower: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationGrowerAddUserArgs = {
  accessPackout: Scalars['Boolean'];
  accessReturns: Scalars['Boolean'];
  email: Scalars['String'];
  growerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  userType?: Maybe<ProfileTypeGrowerAdd>;
};


export type MutationGrowerRemoveUserArgs = {
  growerId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddMiscellaneousFileArgs = {
  docType: DocType;
  files: Array<Maybe<Scalars['Upload']>>;
  grower?: Maybe<Scalars['ID']>;
};


export type MutationAddReturnFileArgs = {
  blockId: Scalars['ID'];
  files: Array<Maybe<Scalars['Upload']>>;
  grower: Scalars['ID'];
};


export type MutationAddFoodSafetyFileArgs = {
  docType: DocType;
  files: Array<Maybe<Scalars['Upload']>>;
  grower: Scalars['ID'];
};


export type MutationAddPackoutFileArgs = {
  blockId: Scalars['ID'];
  files: Array<Maybe<Scalars['Upload']>>;
  grower: Scalars['ID'];
};


export type MutationAddStaffArgs = {
  email: Scalars['String'];
  fieldstaff?: Maybe<Scalars['Boolean']>;
  growerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRemoveStaffArgs = {
  email: Scalars['String'];
};


export type MutationUpdateStaffArgs = {
  email: Scalars['String'];
  isFieldStaff: Scalars['Boolean'];
};


export type MutationAddTokenArgs = {
  name: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDeleteTokenArgs = {
  id: Scalars['ID'];
};


export type MutationInvalidateTokenArgs = {
  id: Scalars['ID'];
};


export type MutationPasswordResetArgs = {
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
};


export type MutationPasswordChangeArgs = {
  oldPassword: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};


export type MutationVerifyTokenArgs = {
  token: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationRevokeTokenArgs = {
  refreshToken: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ObtainJsonWebToken = {
  token: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
  user: Maybe<UserNode>;
  unarchiving: Maybe<Scalars['Boolean']>;
  isFieldStaff: Maybe<Scalars['Boolean']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
};

export type PaginationInfo = {
  totalCount: Maybe<Scalars['Int']>;
  totalPages: Maybe<Scalars['Int']>;
  pageLimit: Maybe<Scalars['Int']>;
};

/**
 * Change account password when user knows the old password.
 *
 * A new token and refresh token are sent. User must be verified.
 */
export type PasswordChange = {
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
  token: Maybe<Scalars['String']>;
};

/**
 * Change user password without old password.
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordReset = {
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
};

export type PoolId = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  value: Scalars['String'];
  closed: Scalars['Boolean'];
};

export type PoolIdConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PoolIdEdge>>;
};

/** A Relay edge containing a `PoolID` and its cursor. */
export type PoolIdEdge = {
  /** The item at the end of the edge */
  node: Maybe<PoolId>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PoolSearch = {
  pageInfo: Maybe<PaginationInfo>;
  pools: Maybe<Array<Maybe<PoolId>>>;
};

export type Profile = Node & {
  user: User;
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  phone: Scalars['String'];
  receiveEmail: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
  isInvited: Scalars['Boolean'];
  isAccepted: Scalars['Boolean'];
  isFieldStaff: Scalars['Boolean'];
  events: ProfileEventTrackerTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Maybe<Scalars['Boolean']>;
  userType: Maybe<ProfileTypeSearch>;
};


export type ProfileEventsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

/** An enumeration. */
export enum ProfileEventTrackerEventType {
  /** LOGGED_IN */
  LoggedIn = 'LOGGED_IN',
  /** LOGGED_IN_ATTEMPT */
  LoggedInAttempt = 'LOGGED_IN_ATTEMPT',
  /** NOTIFICATION_EMAIL_SENT */
  NotificationEmailSent = 'NOTIFICATION_EMAIL_SENT',
  /** NOTIFICATION_EMAIL_DELIVERED */
  NotificationEmailDelivered = 'NOTIFICATION_EMAIL_DELIVERED',
  /** NOTIFICATION_EMAIL_OPENED */
  NotificationEmailOpened = 'NOTIFICATION_EMAIL_OPENED',
  /** NOTIFICATION_EMAIL_CLICKED */
  NotificationEmailClicked = 'NOTIFICATION_EMAIL_CLICKED',
  /** NOTIFICATION_SMS_SENT */
  NotificationSmsSent = 'NOTIFICATION_SMS_SENT',
  /** INVITE_EMAIL_SENT */
  InviteEmailSent = 'INVITE_EMAIL_SENT'
}

export type ProfileEventTrackerType = Node & {
  created: Scalars['DateTime'];
  eventType: ProfileEventTrackerEventType;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ProfileEventTrackerTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfileEventTrackerTypeEdge>>;
};

/** A Relay edge containing a `ProfileEventTrackerType` and its cursor. */
export type ProfileEventTrackerTypeEdge = {
  /** The item at the end of the edge */
  node: Maybe<ProfileEventTrackerType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProfileSearch = {
  pageInfo: Maybe<PaginationInfo>;
  users: Maybe<Array<Maybe<Profile>>>;
};

export type ProfileSignup = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type ProfileToUseInvitedSchema = Node & {
  isFieldStaff: Scalars['Boolean'];
  email: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ProfileToUser = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  grower: Maybe<Grower>;
  isStaff: Scalars['Boolean'];
  email: Scalars['String'];
  accessReturns: Scalars['Boolean'];
};

export type ProfileToUserLink = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  grower: Grower;
  accessPackout: Scalars['Boolean'];
  accessReturns: Scalars['Boolean'];
};

/** An enumeration. */
export enum ProfileType {
  Grower = 'GROWER',
  FieldStaff = 'FIELD_STAFF',
  Staff = 'STAFF'
}

/** An enumeration. */
export enum ProfileTypeCreate {
  Grower = 'GROWER',
  FieldStaff = 'FIELD_STAFF',
  Staff = 'STAFF'
}

/** An enumeration. */
export enum ProfileTypeGrowerAdd {
  Grower = 'GROWER',
  FieldStaff = 'FIELD_STAFF',
  Staff = 'STAFF'
}

/** An enumeration. */
export enum ProfileTypeSearch {
  Grower = 'GROWER',
  FieldStaff = 'FIELD_STAFF',
  Staff = 'STAFF'
}

export type ProfileUpdateMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type Query = {
  allStaff: Maybe<UserSearch>;
  allInvited: Maybe<UserInvited>;
  allInvitedStaff: Maybe<UserInvited>;
  myProfile: Maybe<RawProfile>;
  profile: Maybe<RawProfile>;
  allProfiles: Maybe<ProfileSearch>;
  graph: Maybe<CachedGraphData>;
  allGraphs: Maybe<GraphConnection>;
  cropYear: Maybe<CachedCropYear>;
  cherryCropYear: Maybe<CachedCherryCropYear>;
  /** The ID of the object */
  pool: Maybe<PoolId>;
  allPools: Maybe<PoolSearch>;
  allBlocks: Maybe<BlockSearch>;
  /** The ID of the object */
  file: Maybe<File>;
  allFileTypes: Maybe<FileTypesConnection>;
  allFiles: Maybe<FileSearch>;
  /** The ID of the object */
  grower: Maybe<Grower>;
  allGrowers: Maybe<GrowerSearch>;
  /** The ID of the object */
  setting: Maybe<Settings>;
  allSettings: Maybe<SettingsConnection>;
  /** The ID of the object */
  commodity: Maybe<Commodity>;
  allCommodities: Maybe<CommodityConnection>;
  /** The ID of the object */
  variety: Maybe<Variety>;
  allVarieties: Maybe<VarietySearch>;
  allTokens: Maybe<TableauApiKeySchemaConnection>;
  /** The ID of the object */
  node: Maybe<Node>;
};


export type QueryAllStaffArgs = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAllInvitedArgs = {
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAllInvitedStaffArgs = {
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProfileArgs = {
  profile?: Maybe<Scalars['ID']>;
};


export type QueryAllProfilesArgs = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  userType?: Maybe<ProfileType>;
};


export type QueryGraphArgs = {
  graphMachine: Scalars['String'];
  grower: Scalars['ID'];
  pool?: Maybe<Scalars['ID']>;
  block?: Maybe<Scalars['ID']>;
};


export type QueryAllGraphsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  machine?: Maybe<Scalars['String']>;
};


export type QueryCropYearArgs = {
  growerId: Scalars['ID'];
};


export type QueryCherryCropYearArgs = {
  growerId: Scalars['ID'];
};


export type QueryPoolArgs = {
  id: Scalars['ID'];
};


export type QueryAllPoolsArgs = {
  graphMachine?: Maybe<Scalars['String']>;
  growerId?: Maybe<Scalars['ID']>;
  block?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAllBlocksArgs = {
  graphMachine?: Maybe<Scalars['String']>;
  poolId?: Maybe<Scalars['ID']>;
  grower?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryAllFileTypesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryAllFilesArgs = {
  grower?: Maybe<Scalars['ID']>;
  fileType?: Maybe<Scalars['ID']>;
  docType?: Maybe<Scalars['String']>;
  poolId?: Maybe<Scalars['ID']>;
  block?: Maybe<Scalars['ID']>;
  cropYear?: Maybe<Scalars['Int']>;
  method?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGrowerArgs = {
  id: Scalars['ID'];
};


export type QueryAllGrowersArgs = {
  graphMachine?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySettingArgs = {
  id: Scalars['ID'];
};


export type QueryAllSettingsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryCommodityArgs = {
  id: Scalars['ID'];
};


export type QueryAllCommoditiesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryVarietyArgs = {
  id: Scalars['ID'];
};


export type QueryAllVarietiesArgs = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAllTokensArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type RawProfile = {
  email: Maybe<Scalars['String']>;
  nameFirst: Maybe<Scalars['String']>;
  nameLast: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  receiveEmail: Maybe<Scalars['Boolean']>;
  receiveSms: Maybe<Scalars['Boolean']>;
  isInvited: Maybe<Scalars['Boolean']>;
  isAccepted: Maybe<Scalars['Boolean']>;
  isActive: Maybe<Scalars['Boolean']>;
  isFieldStaff: Maybe<Scalars['Boolean']>;
  accessPackout: Maybe<Scalars['Boolean']>;
  accessReturns: Maybe<Scalars['Boolean']>;
  growers: Maybe<Array<Maybe<GrowerProfileSchema>>>;
  growerSettings: Maybe<Array<Maybe<ProfileToUserLink>>>;
  events: Maybe<Array<Maybe<ProfileEventTrackerType>>>;
  userId: Maybe<Scalars['Int']>;
  userType: Maybe<Scalars['String']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RefreshToken = {
  token: Maybe<Scalars['String']>;
  payload: Maybe<Scalars['GenericScalar']>;
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
};

export type RemoveFileMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type RemoveStaffMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RevokeToken = {
  revoked: Maybe<Scalars['Int']>;
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
};

/**
 * Send password reset email.
 *
 * For non verified users, send an activation
 * email instead.
 *
 * Accepts both primary and secondary email.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type SendPasswordResetEmail = {
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
};

export enum SettingType {
  GrowerMessage = 'GROWER_MESSAGE'
}

export type SettingUpdateMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type Settings = Node & {
  name: Scalars['String'];
  value: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type SettingsConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SettingsEdge>>;
};

/** A Relay edge containing a `Settings` and its cursor. */
export type SettingsEdge = {
  /** The item at the end of the edge */
  node: Maybe<Settings>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TableauApiKeySchema = Node & {
  name: Scalars['String'];
  token: Scalars['String'];
  valid: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type TableauApiKeySchemaConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TableauApiKeySchemaEdge>>;
};

/** A Relay edge containing a `TableauApiKeySchema` and its cursor. */
export type TableauApiKeySchemaEdge = {
  /** The item at the end of the edge */
  node: Maybe<TableauApiKeySchema>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TargetProfileUpdateMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type TokenVerifyMutation = {
  payload: Maybe<Scalars['GenericScalar']>;
  success: Maybe<Scalars['Boolean']>;
  errors: Maybe<Scalars['ExpectedErrorType']>;
  isSuperuser: Maybe<Scalars['Boolean']>;
  isStaff: Maybe<Scalars['Boolean']>;
  isFieldStaff: Maybe<Scalars['Boolean']>;
};

export type UpdateStaffMutation = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};


export type User = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  lastLogin: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  profile: Maybe<Profile>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
};

export type UserDelete = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type UserInvited = {
  pageInfo: Maybe<PaginationInfo>;
  invited: Maybe<Array<Maybe<ProfileToUseInvitedSchema>>>;
};

export type UserNode = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  lastLogin: Maybe<Scalars['DateTime']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  profile: Maybe<Profile>;
  pk: Maybe<Scalars['Int']>;
  archived: Maybe<Scalars['Boolean']>;
  verified: Maybe<Scalars['Boolean']>;
  secondaryEmail: Maybe<Scalars['String']>;
};

export type UserReinvite = {
  success: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

export type UserSearch = {
  pageInfo: Maybe<PaginationInfo>;
  users: Maybe<Array<Maybe<User>>>;
};

export type Variety = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  commodity: Maybe<Commodity>;
  name: Scalars['String'];
  code: Maybe<Scalars['String']>;
};

export type VarietyConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VarietyEdge>>;
};

/** A Relay edge containing a `Variety` and its cursor. */
export type VarietyEdge = {
  /** The item at the end of the edge */
  node: Maybe<Variety>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type VarietySearch = {
  pageInfo: Maybe<PaginationInfo>;
  varieties: Maybe<Array<Maybe<Variety>>>;
};

export type AuthorizeMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthorizeMutation = { tokenAuth: Maybe<(
    Pick<ObtainJsonWebToken, 'token' | 'success' | 'errors' | 'isFieldStaff'>
    & { user: Maybe<Pick<UserNode, 'isStaff'>> }
  )> };

export type AddGlobalMessageMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type AddGlobalMessageMutation = { settingUpdate: Maybe<Pick<SettingUpdateMutation, 'message' | 'success'>> };

export type UpdateMyProfileMutationVariables = Exact<{
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receiveEmail?: Maybe<Scalars['Boolean']>;
  receiveSms?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateMyProfileMutation = { profileUpdate: Maybe<Pick<ProfileUpdateMutation, 'success' | 'message'>> };

export type ProfileSignupMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat: Scalars['String'];
  phone: Scalars['String'];
  receiveEmail: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
}>;


export type ProfileSignupMutation = { profileSignup: Maybe<Pick<ProfileSignup, 'success' | 'message'>> };

export type AddMiscellaneousFilesMutationVariables = Exact<{
  docType: DocType;
  files: Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>;
  grower?: Maybe<Scalars['ID']>;
}>;


export type AddMiscellaneousFilesMutation = { addMiscellaneousFile: Maybe<Pick<AddMiscellaneousFileMutation, 'success' | 'message'>> };

export type AddPackoutFilesMutationVariables = Exact<{
  grower: Scalars['ID'];
  block: Scalars['ID'];
  files: Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>;
}>;


export type AddPackoutFilesMutation = { addPackoutFile: Maybe<Pick<AddPackoutFileMutation, 'success' | 'message'>> };

export type AddReturnStatementsMutationVariables = Exact<{
  grower: Scalars['ID'];
  block: Scalars['ID'];
  files: Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>;
}>;


export type AddReturnStatementsMutation = { addReturnFile: Maybe<Pick<AddReturnFileMutation, 'success' | 'message'>> };

export type AddFoodSafetyFilesMutationVariables = Exact<{
  docType: DocType;
  grower: Scalars['ID'];
  files: Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>;
}>;


export type AddFoodSafetyFilesMutation = { addFoodSafetyFile: Maybe<Pick<AddFoodSafetyFileMutation, 'success' | 'message'>> };

export type DeleteFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type DeleteFileMutation = { fileDelete: Maybe<Pick<RemoveFileMutation, 'success' | 'message'>> };

export type AddGrowerUserMutationVariables = Exact<{
  email: Scalars['String'];
  userType?: Maybe<ProfileTypeGrowerAdd>;
  growerIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  accessPackout: Scalars['Boolean'];
  accessReturns: Scalars['Boolean'];
}>;


export type AddGrowerUserMutation = { growerAddUser: Maybe<Pick<GrowerAddUserMutation, 'success' | 'noAccess' | 'invitationSent' | 'added' | 'alreadyAdded' | 'message'>> };

export type AddStaffUserMutationVariables = Exact<{
  email: Scalars['String'];
  isFieldStaff: Scalars['Boolean'];
  growerIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type AddStaffUserMutation = { addStaff: Maybe<Pick<AddStaffMutation, 'success' | 'message'>> };

export type UpdateStaffUserMutationVariables = Exact<{
  email: Scalars['String'];
  isFieldStaff: Scalars['Boolean'];
}>;


export type UpdateStaffUserMutation = { updateStaff: Maybe<Pick<UpdateStaffMutation, 'success' | 'message'>> };

export type RemoveStaffUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RemoveStaffUserMutation = { removeStaff: Maybe<Pick<RemoveStaffMutation, 'success' | 'message'>> };

export type RemoveGrowerUserMutationVariables = Exact<{
  grower: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveGrowerUserMutation = { growerRemoveUser: Maybe<Pick<GrowerRemoveUserMutation, 'success' | 'message'>> };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordEmailMutation = { sendPasswordResetEmail: Maybe<Pick<SendPasswordResetEmail, 'success' | 'errors'>> };

export type ResetPasswordThroughEmailCodeMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordThroughEmailCodeMutation = { passwordReset: Maybe<Pick<PasswordReset, 'success' | 'errors'>> };

export type ResetPasswordThroughOldPasswordMutationVariables = Exact<{
  oldPass: Scalars['String'];
  newPass: Scalars['String'];
}>;


export type ResetPasswordThroughOldPasswordMutation = { passwordChange: Maybe<Pick<PasswordChange, 'success' | 'token' | 'errors'>> };

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenMutation = { verifyToken: Maybe<Pick<TokenVerifyMutation, 'isStaff' | 'isFieldStaff' | 'success' | 'errors'>> };

export type UserDeleteMutationVariables = Exact<{
  userid: Scalars['ID'];
}>;


export type UserDeleteMutation = { userDelete: Maybe<Pick<UserDelete, 'success' | 'message'>> };

export type UserReinviteMutationVariables = Exact<{
  userid: Scalars['ID'];
}>;


export type UserReinviteMutation = { userReinvite: Maybe<Pick<UserReinvite, 'success' | 'message'>> };

export type UpdateProfileMutationVariables = Exact<{
  userId: Scalars['ID'];
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userType?: Maybe<ProfileTypeCreate>;
}>;


export type UpdateProfileMutation = { profileTargetUpdate: Maybe<Pick<TargetProfileUpdateMutation, 'success' | 'message'>> };

export type TokenDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TokenDeleteMutation = { deleteToken: Maybe<Pick<DeleteTokenMutation, 'success' | 'message'>> };

export type TokenAddMutationVariables = Exact<{
  name: Scalars['String'];
  token: Scalars['String'];
}>;


export type TokenAddMutation = { addToken: Maybe<Pick<AddTokenMutation, 'success' | 'message'>> };

export type AllFileFieldsFragment = (
  Pick<File, 'name' | 'id' | 'cropYear' | 'docType' | 'path'>
  & { variety: Maybe<Pick<Variety, 'name' | 'id'>>, grower: Maybe<Pick<Grower, 'name' | 'id'>>, type: Maybe<Pick<FileTypes, 'name' | 'id'>> }
);

export type AllFileUserFieldsFragment = (
  Pick<FileUser, 'name' | 'id' | 'cropYear' | 'docType' | 'path'>
  & { variety: Maybe<Pick<Variety, 'name' | 'id'>>, grower: Maybe<Pick<Grower, 'name' | 'id'>>, type: Maybe<Pick<FileTypes, 'name' | 'id'>> }
);

export type GrowerFieldsFragment = (
  Pick<Grower, 'id' | 'internalId' | 'commodities'>
  & { name: Grower['formattedName'] }
  & { activeUsers: Maybe<Array<Maybe<(
    Pick<Profile, 'id'>
    & { user: Pick<User, 'email'> }
  )>>>, nonActiveUsers: Maybe<Array<Maybe<(
    Pick<Profile, 'id'>
    & { user: Pick<User, 'email'> }
  )>>>, packouts: Maybe<{ files: Maybe<Array<Maybe<AllFileFieldsFragment>>> }>, returnStatements: Maybe<{ files: Maybe<Array<Maybe<AllFileFieldsFragment>>> }>, miscellaneous: Maybe<{ files: Maybe<Array<Maybe<AllFileFieldsFragment>>> }>, foodSafety: Maybe<{ files: Maybe<Array<Maybe<AllFileFieldsFragment>>> }> }
);

export type AllGrowersFieldsFragment = (
  Pick<GrowerProfileSchema, 'id' | 'internalId' | 'commodities'>
  & { name: GrowerProfileSchema['formattedName'] }
  & { activeUsers: Maybe<Array<Maybe<(
    Pick<Profile, 'id'>
    & { user: Pick<User, 'email'> }
  )>>>, nonActiveUsers: Maybe<Array<Maybe<(
    Pick<Profile, 'id'>
    & { user: Pick<User, 'email'> }
  )>>> }
);

export type GetAllFilesQueryVariables = Exact<{
  fileType?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['ID']>;
  grower?: Maybe<Scalars['ID']>;
  cropYear?: Maybe<Scalars['Int']>;
  method?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllFilesQuery = { allFiles: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, files: Maybe<Array<Maybe<AllFileFieldsFragment>>> }> };

export type GetAllVarietiesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllVarietiesQuery = { allVarieties: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, varieties: Maybe<Array<Maybe<Pick<Variety, 'name' | 'id'>>>> }> };

export type GetAllStaffUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllStaffUsersQuery = { allStaff: Maybe<{ users: Maybe<Array<Maybe<(
      Pick<User, 'id' | 'email'>
      & { profile: Maybe<Pick<Profile, 'nameFirst' | 'nameLast' | 'isFieldStaff'>> }
    )>>> }> };

export type GetAllInvitedStaffUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllInvitedStaffUsersQuery = { allInvitedStaff: Maybe<{ invited: Maybe<Array<Maybe<Pick<ProfileToUseInvitedSchema, 'id' | 'isFieldStaff' | 'email'>>>> }> };

export type GetAllGrowersQueryVariables = Exact<{
  graphMachine?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllGrowersQuery = { allGrowers: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, growers: Maybe<Array<Maybe<(
      Pick<Grower, 'id'>
      & { name: Grower['formattedName'] }
    )>>> }> };

export type GetAllGrowersWithUsersQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllGrowersWithUsersQuery = { allGrowers: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, growers: Maybe<Array<Maybe<(
      Pick<Grower, 'id'>
      & { name: Grower['formattedName'] }
      & { users: Maybe<Array<Maybe<Pick<Profile, 'id' | 'nameFirst' | 'nameLast'>>>> }
    )>>> }> };

export type GetAllGrowerUsersQueryVariables = Exact<{
  growerId: Scalars['ID'];
}>;


export type GetAllGrowerUsersQuery = { grower: Maybe<{ activeUsers: Maybe<Array<Maybe<{ user: Pick<User, 'id' | 'email'> }>>>, invitedUsers: Maybe<Array<Maybe<Pick<ProfileToUser, 'id' | 'email' | 'accessReturns'>>>> }> };

export type GetAllUsersQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  userType?: Maybe<ProfileType>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllUsersQuery = { allProfiles: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, users: Maybe<Array<Maybe<(
      Pick<Profile, 'id' | 'nameFirst' | 'nameLast'>
      & { user: Pick<User, 'id' | 'email' | 'lastLogin'>, events: { edges: Array<Maybe<{ node: Maybe<Pick<ProfileEventTrackerType, 'eventType'>> }>> } }
    )>>> }> };

export type GetAllInvitedQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllInvitedQuery = { allInvited: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, invited: Maybe<Array<Maybe<Pick<ProfileToUseInvitedSchema, 'id' | 'email'>>>> }> };

export type GetAllBlocksQueryVariables = Exact<{
  graphMachine?: Maybe<Scalars['String']>;
  grower?: Maybe<Scalars['ID']>;
  poolId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllBlocksQuery = { allBlocks: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, blocks: Maybe<Array<Maybe<Pick<Block, 'name' | 'id'>>>> }> };

export type GetAllPoolsQueryVariables = Exact<{
  graphMachine?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['ID']>;
  growerId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAllPoolsQuery = { allPools: Maybe<{ pageInfo: Maybe<Pick<PaginationInfo, 'totalCount' | 'totalPages' | 'pageLimit'>>, pools: Maybe<Array<Maybe<Pick<PoolId, 'value' | 'id' | 'closed'>>>> }> };

export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = { myProfile: Maybe<(
    Pick<RawProfile, 'email' | 'nameLast' | 'nameFirst' | 'phone' | 'receiveSms' | 'receiveEmail' | 'accessPackout' | 'accessReturns'>
    & { growers: Maybe<Array<Maybe<AllGrowersFieldsFragment>>> }
  )> };

export type GetGlobalGrowerMessageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalGrowerMessageQuery = { setting: Maybe<{ message: Settings['value'] }> };

export type GetGrowerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGrowerQuery = { grower: Maybe<GrowerFieldsFragment> };

export type GetGraphQueryVariables = Exact<{
  graphMachine: Scalars['String'];
  growerId: Scalars['ID'];
  poolId?: Maybe<Scalars['ID']>;
  blockId?: Maybe<Scalars['ID']>;
}>;


export type GetGraphQuery = { graph: Maybe<(
    Pick<CachedGraphData, 'created' | 'modified' | 'data' | 'stats'>
    & { pool: Maybe<Pick<PoolId, 'id' | 'value' | 'closed'>>, block: Maybe<Pick<Block, 'id' | 'name'>>, graph: Pick<Graph, 'id' | 'name'> }
  )> };

export type GetCropProductionDataQueryVariables = Exact<{
  growerId: Scalars['ID'];
}>;


export type GetCropProductionDataQuery = { cropProduction: Maybe<Pick<CachedCropYear, 'cherry' | 'apple' | 'pear'>> };

export type GetProfileQueryVariables = Exact<{
  profile: Scalars['ID'];
}>;


export type GetProfileQuery = { profile: Maybe<(
    Pick<RawProfile, 'userId' | 'email' | 'phone' | 'nameFirst' | 'nameLast' | 'isFieldStaff' | 'userType'>
    & { growerSettings: Maybe<Array<Maybe<(
      Pick<ProfileToUserLink, 'accessReturns' | 'accessPackout'>
      & { grower: GrowerFieldsFragment }
    )>>>, events: Maybe<Array<Maybe<Pick<ProfileEventTrackerType, 'created' | 'eventType'>>>> }
  )> };

export type GetAllTokensQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetAllTokensQuery = { allTokens: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>, edges: Array<Maybe<{ node: Maybe<Pick<TableauApiKeySchema, 'id' | 'name' | 'token' | 'valid'>> }>> }> };

export const AllFileUserFieldsFragmentDoc = gql`
    fragment allFileUserFields on FileUser {
  name
  id
  cropYear
  docType
  variety {
    name
    id
  }
  grower {
    name
    id
  }
  path
  type {
    name
    id
  }
}
    `;
export const AllFileFieldsFragmentDoc = gql`
    fragment allFileFields on File {
  name
  id
  cropYear
  docType
  variety {
    name
    id
  }
  grower {
    name
    id
  }
  path
  type {
    name
    id
  }
}
    `;
export const GrowerFieldsFragmentDoc = gql`
    fragment growerFields on Grower {
  name: formattedName
  id
  internalId
  commodities
  activeUsers: users(isActive: true) {
    id
    user {
      email
    }
  }
  nonActiveUsers: users(isActive: false) {
    id
    user {
      email
    }
  }
  packouts: files(limit: 3, fileType: "RmlsZVR5cGVzOjU=") {
    files {
      ...allFileFields
    }
  }
  returnStatements: files(limit: 3, fileType: "RmlsZVR5cGVzOjc=") {
    files {
      ...allFileFields
    }
  }
  miscellaneous: files(limit: 3, fileType: "RmlsZVR5cGVzOjg=") {
    files {
      ...allFileFields
    }
  }
  foodSafety: files(limit: 3, fileType: "RmlsZVR5cGVzOjY=") {
    files {
      ...allFileFields
    }
  }
}
    ${AllFileFieldsFragmentDoc}`;
export const AllGrowersFieldsFragmentDoc = gql`
    fragment allGrowersFields on GrowerProfileSchema {
  name: formattedName
  id
  internalId
  commodities
  activeUsers: users(isActive: true) {
    id
    user {
      email
    }
  }
  nonActiveUsers: users(isActive: false) {
    id
    user {
      email
    }
  }
}
    `;
export const AuthorizeDocument = gql`
    mutation authorize($email: String!, $password: String!) {
  tokenAuth(password: $password, email: $email) {
    token
    success
    errors
    isFieldStaff
    user {
      isStaff
    }
  }
}
    `;
export type AuthorizeMutationFn = Apollo.MutationFunction<AuthorizeMutation, AuthorizeMutationVariables>;

/**
 * __useAuthorizeMutation__
 *
 * To run a mutation, you first call `useAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeMutation, { data, loading, error }] = useAuthorizeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthorizeMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeMutation, AuthorizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeMutation, AuthorizeMutationVariables>(AuthorizeDocument, options);
      }
export type AuthorizeMutationHookResult = ReturnType<typeof useAuthorizeMutation>;
export type AuthorizeMutationResult = Apollo.MutationResult<AuthorizeMutation>;
export type AuthorizeMutationOptions = Apollo.BaseMutationOptions<AuthorizeMutation, AuthorizeMutationVariables>;
export const AddGlobalMessageDocument = gql`
    mutation addGlobalMessage($message: String!) {
  settingUpdate(name: GROWER_MESSAGE, value: $message) {
    message
    success
  }
}
    `;
export type AddGlobalMessageMutationFn = Apollo.MutationFunction<AddGlobalMessageMutation, AddGlobalMessageMutationVariables>;

/**
 * __useAddGlobalMessageMutation__
 *
 * To run a mutation, you first call `useAddGlobalMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalMessageMutation, { data, loading, error }] = useAddGlobalMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddGlobalMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddGlobalMessageMutation, AddGlobalMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGlobalMessageMutation, AddGlobalMessageMutationVariables>(AddGlobalMessageDocument, options);
      }
export type AddGlobalMessageMutationHookResult = ReturnType<typeof useAddGlobalMessageMutation>;
export type AddGlobalMessageMutationResult = Apollo.MutationResult<AddGlobalMessageMutation>;
export type AddGlobalMessageMutationOptions = Apollo.BaseMutationOptions<AddGlobalMessageMutation, AddGlobalMessageMutationVariables>;
export const UpdateMyProfileDocument = gql`
    mutation updateMyProfile($nameFirst: String, $nameLast: String, $phone: String, $receiveEmail: Boolean, $receiveSms: Boolean) {
  profileUpdate(
    nameFirst: $nameFirst
    nameLast: $nameLast
    phone: $phone
    receiveEmail: $receiveEmail
    receiveSms: $receiveSms
  ) {
    success
    message
  }
}
    `;
export type UpdateMyProfileMutationFn = Apollo.MutationFunction<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>;

/**
 * __useUpdateMyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProfileMutation, { data, loading, error }] = useUpdateMyProfileMutation({
 *   variables: {
 *      nameFirst: // value for 'nameFirst'
 *      nameLast: // value for 'nameLast'
 *      phone: // value for 'phone'
 *      receiveEmail: // value for 'receiveEmail'
 *      receiveSms: // value for 'receiveSms'
 *   },
 * });
 */
export function useUpdateMyProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(UpdateMyProfileDocument, options);
      }
export type UpdateMyProfileMutationHookResult = ReturnType<typeof useUpdateMyProfileMutation>;
export type UpdateMyProfileMutationResult = Apollo.MutationResult<UpdateMyProfileMutation>;
export type UpdateMyProfileMutationOptions = Apollo.BaseMutationOptions<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>;
export const ProfileSignupDocument = gql`
    mutation profileSignup($email: String!, $token: String!, $nameFirst: String!, $nameLast: String!, $password: String!, $passwordRepeat: String!, $phone: String!, $receiveEmail: Boolean!, $receiveSms: Boolean!) {
  profileSignup(
    email: $email
    token: $token
    nameFirst: $nameFirst
    nameLast: $nameLast
    password: $password
    passwordRepeat: $passwordRepeat
    phone: $phone
    receiveEmail: $receiveEmail
    receiveSms: $receiveSms
  ) {
    success
    message
  }
}
    `;
export type ProfileSignupMutationFn = Apollo.MutationFunction<ProfileSignupMutation, ProfileSignupMutationVariables>;

/**
 * __useProfileSignupMutation__
 *
 * To run a mutation, you first call `useProfileSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileSignupMutation, { data, loading, error }] = useProfileSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      nameFirst: // value for 'nameFirst'
 *      nameLast: // value for 'nameLast'
 *      password: // value for 'password'
 *      passwordRepeat: // value for 'passwordRepeat'
 *      phone: // value for 'phone'
 *      receiveEmail: // value for 'receiveEmail'
 *      receiveSms: // value for 'receiveSms'
 *   },
 * });
 */
export function useProfileSignupMutation(baseOptions?: Apollo.MutationHookOptions<ProfileSignupMutation, ProfileSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileSignupMutation, ProfileSignupMutationVariables>(ProfileSignupDocument, options);
      }
export type ProfileSignupMutationHookResult = ReturnType<typeof useProfileSignupMutation>;
export type ProfileSignupMutationResult = Apollo.MutationResult<ProfileSignupMutation>;
export type ProfileSignupMutationOptions = Apollo.BaseMutationOptions<ProfileSignupMutation, ProfileSignupMutationVariables>;
export const AddMiscellaneousFilesDocument = gql`
    mutation addMiscellaneousFiles($docType: DocType!, $files: [Upload]!, $grower: ID) {
  addMiscellaneousFile(docType: $docType, files: $files, grower: $grower) {
    success
    message
  }
}
    `;
export type AddMiscellaneousFilesMutationFn = Apollo.MutationFunction<AddMiscellaneousFilesMutation, AddMiscellaneousFilesMutationVariables>;

/**
 * __useAddMiscellaneousFilesMutation__
 *
 * To run a mutation, you first call `useAddMiscellaneousFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMiscellaneousFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMiscellaneousFilesMutation, { data, loading, error }] = useAddMiscellaneousFilesMutation({
 *   variables: {
 *      docType: // value for 'docType'
 *      files: // value for 'files'
 *      grower: // value for 'grower'
 *   },
 * });
 */
export function useAddMiscellaneousFilesMutation(baseOptions?: Apollo.MutationHookOptions<AddMiscellaneousFilesMutation, AddMiscellaneousFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMiscellaneousFilesMutation, AddMiscellaneousFilesMutationVariables>(AddMiscellaneousFilesDocument, options);
      }
export type AddMiscellaneousFilesMutationHookResult = ReturnType<typeof useAddMiscellaneousFilesMutation>;
export type AddMiscellaneousFilesMutationResult = Apollo.MutationResult<AddMiscellaneousFilesMutation>;
export type AddMiscellaneousFilesMutationOptions = Apollo.BaseMutationOptions<AddMiscellaneousFilesMutation, AddMiscellaneousFilesMutationVariables>;
export const AddPackoutFilesDocument = gql`
    mutation addPackoutFiles($grower: ID!, $block: ID!, $files: [Upload]!) {
  addPackoutFile(grower: $grower, blockId: $block, files: $files) {
    success
    message
  }
}
    `;
export type AddPackoutFilesMutationFn = Apollo.MutationFunction<AddPackoutFilesMutation, AddPackoutFilesMutationVariables>;

/**
 * __useAddPackoutFilesMutation__
 *
 * To run a mutation, you first call `useAddPackoutFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPackoutFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPackoutFilesMutation, { data, loading, error }] = useAddPackoutFilesMutation({
 *   variables: {
 *      grower: // value for 'grower'
 *      block: // value for 'block'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useAddPackoutFilesMutation(baseOptions?: Apollo.MutationHookOptions<AddPackoutFilesMutation, AddPackoutFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPackoutFilesMutation, AddPackoutFilesMutationVariables>(AddPackoutFilesDocument, options);
      }
export type AddPackoutFilesMutationHookResult = ReturnType<typeof useAddPackoutFilesMutation>;
export type AddPackoutFilesMutationResult = Apollo.MutationResult<AddPackoutFilesMutation>;
export type AddPackoutFilesMutationOptions = Apollo.BaseMutationOptions<AddPackoutFilesMutation, AddPackoutFilesMutationVariables>;
export const AddReturnStatementsDocument = gql`
    mutation addReturnStatements($grower: ID!, $block: ID!, $files: [Upload]!) {
  addReturnFile(grower: $grower, blockId: $block, files: $files) {
    success
    message
  }
}
    `;
export type AddReturnStatementsMutationFn = Apollo.MutationFunction<AddReturnStatementsMutation, AddReturnStatementsMutationVariables>;

/**
 * __useAddReturnStatementsMutation__
 *
 * To run a mutation, you first call `useAddReturnStatementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReturnStatementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReturnStatementsMutation, { data, loading, error }] = useAddReturnStatementsMutation({
 *   variables: {
 *      grower: // value for 'grower'
 *      block: // value for 'block'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useAddReturnStatementsMutation(baseOptions?: Apollo.MutationHookOptions<AddReturnStatementsMutation, AddReturnStatementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReturnStatementsMutation, AddReturnStatementsMutationVariables>(AddReturnStatementsDocument, options);
      }
export type AddReturnStatementsMutationHookResult = ReturnType<typeof useAddReturnStatementsMutation>;
export type AddReturnStatementsMutationResult = Apollo.MutationResult<AddReturnStatementsMutation>;
export type AddReturnStatementsMutationOptions = Apollo.BaseMutationOptions<AddReturnStatementsMutation, AddReturnStatementsMutationVariables>;
export const AddFoodSafetyFilesDocument = gql`
    mutation addFoodSafetyFiles($docType: DocType!, $grower: ID!, $files: [Upload]!) {
  addFoodSafetyFile(docType: $docType, grower: $grower, files: $files) {
    success
    message
  }
}
    `;
export type AddFoodSafetyFilesMutationFn = Apollo.MutationFunction<AddFoodSafetyFilesMutation, AddFoodSafetyFilesMutationVariables>;

/**
 * __useAddFoodSafetyFilesMutation__
 *
 * To run a mutation, you first call `useAddFoodSafetyFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoodSafetyFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoodSafetyFilesMutation, { data, loading, error }] = useAddFoodSafetyFilesMutation({
 *   variables: {
 *      docType: // value for 'docType'
 *      grower: // value for 'grower'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useAddFoodSafetyFilesMutation(baseOptions?: Apollo.MutationHookOptions<AddFoodSafetyFilesMutation, AddFoodSafetyFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFoodSafetyFilesMutation, AddFoodSafetyFilesMutationVariables>(AddFoodSafetyFilesDocument, options);
      }
export type AddFoodSafetyFilesMutationHookResult = ReturnType<typeof useAddFoodSafetyFilesMutation>;
export type AddFoodSafetyFilesMutationResult = Apollo.MutationResult<AddFoodSafetyFilesMutation>;
export type AddFoodSafetyFilesMutationOptions = Apollo.BaseMutationOptions<AddFoodSafetyFilesMutation, AddFoodSafetyFilesMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($fileId: ID!) {
  fileDelete(fileId: $fileId) {
    success
    message
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const AddGrowerUserDocument = gql`
    mutation addGrowerUser($email: String!, $userType: ProfileTypeGrowerAdd, $growerIds: [ID]!, $accessPackout: Boolean!, $accessReturns: Boolean!) {
  growerAddUser(
    email: $email
    userType: $userType
    growerIds: $growerIds
    accessPackout: $accessPackout
    accessReturns: $accessReturns
  ) {
    success
    noAccess
    invitationSent
    added
    alreadyAdded
    message
  }
}
    `;
export type AddGrowerUserMutationFn = Apollo.MutationFunction<AddGrowerUserMutation, AddGrowerUserMutationVariables>;

/**
 * __useAddGrowerUserMutation__
 *
 * To run a mutation, you first call `useAddGrowerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGrowerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGrowerUserMutation, { data, loading, error }] = useAddGrowerUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userType: // value for 'userType'
 *      growerIds: // value for 'growerIds'
 *      accessPackout: // value for 'accessPackout'
 *      accessReturns: // value for 'accessReturns'
 *   },
 * });
 */
export function useAddGrowerUserMutation(baseOptions?: Apollo.MutationHookOptions<AddGrowerUserMutation, AddGrowerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGrowerUserMutation, AddGrowerUserMutationVariables>(AddGrowerUserDocument, options);
      }
export type AddGrowerUserMutationHookResult = ReturnType<typeof useAddGrowerUserMutation>;
export type AddGrowerUserMutationResult = Apollo.MutationResult<AddGrowerUserMutation>;
export type AddGrowerUserMutationOptions = Apollo.BaseMutationOptions<AddGrowerUserMutation, AddGrowerUserMutationVariables>;
export const AddStaffUserDocument = gql`
    mutation addStaffUser($email: String!, $isFieldStaff: Boolean!, $growerIds: [ID]) {
  addStaff(email: $email, fieldstaff: $isFieldStaff, growerIds: $growerIds) {
    success
    message
  }
}
    `;
export type AddStaffUserMutationFn = Apollo.MutationFunction<AddStaffUserMutation, AddStaffUserMutationVariables>;

/**
 * __useAddStaffUserMutation__
 *
 * To run a mutation, you first call `useAddStaffUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffUserMutation, { data, loading, error }] = useAddStaffUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isFieldStaff: // value for 'isFieldStaff'
 *      growerIds: // value for 'growerIds'
 *   },
 * });
 */
export function useAddStaffUserMutation(baseOptions?: Apollo.MutationHookOptions<AddStaffUserMutation, AddStaffUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStaffUserMutation, AddStaffUserMutationVariables>(AddStaffUserDocument, options);
      }
export type AddStaffUserMutationHookResult = ReturnType<typeof useAddStaffUserMutation>;
export type AddStaffUserMutationResult = Apollo.MutationResult<AddStaffUserMutation>;
export type AddStaffUserMutationOptions = Apollo.BaseMutationOptions<AddStaffUserMutation, AddStaffUserMutationVariables>;
export const UpdateStaffUserDocument = gql`
    mutation updateStaffUser($email: String!, $isFieldStaff: Boolean!) {
  updateStaff(email: $email, isFieldStaff: $isFieldStaff) {
    success
    message
  }
}
    `;
export type UpdateStaffUserMutationFn = Apollo.MutationFunction<UpdateStaffUserMutation, UpdateStaffUserMutationVariables>;

/**
 * __useUpdateStaffUserMutation__
 *
 * To run a mutation, you first call `useUpdateStaffUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffUserMutation, { data, loading, error }] = useUpdateStaffUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isFieldStaff: // value for 'isFieldStaff'
 *   },
 * });
 */
export function useUpdateStaffUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffUserMutation, UpdateStaffUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffUserMutation, UpdateStaffUserMutationVariables>(UpdateStaffUserDocument, options);
      }
export type UpdateStaffUserMutationHookResult = ReturnType<typeof useUpdateStaffUserMutation>;
export type UpdateStaffUserMutationResult = Apollo.MutationResult<UpdateStaffUserMutation>;
export type UpdateStaffUserMutationOptions = Apollo.BaseMutationOptions<UpdateStaffUserMutation, UpdateStaffUserMutationVariables>;
export const RemoveStaffUserDocument = gql`
    mutation removeStaffUser($email: String!) {
  removeStaff(email: $email) {
    success
    message
  }
}
    `;
export type RemoveStaffUserMutationFn = Apollo.MutationFunction<RemoveStaffUserMutation, RemoveStaffUserMutationVariables>;

/**
 * __useRemoveStaffUserMutation__
 *
 * To run a mutation, you first call `useRemoveStaffUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStaffUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStaffUserMutation, { data, loading, error }] = useRemoveStaffUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveStaffUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStaffUserMutation, RemoveStaffUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStaffUserMutation, RemoveStaffUserMutationVariables>(RemoveStaffUserDocument, options);
      }
export type RemoveStaffUserMutationHookResult = ReturnType<typeof useRemoveStaffUserMutation>;
export type RemoveStaffUserMutationResult = Apollo.MutationResult<RemoveStaffUserMutation>;
export type RemoveStaffUserMutationOptions = Apollo.BaseMutationOptions<RemoveStaffUserMutation, RemoveStaffUserMutationVariables>;
export const RemoveGrowerUserDocument = gql`
    mutation removeGrowerUser($grower: ID!, $userId: ID!) {
  growerRemoveUser(growerId: $grower, userId: $userId) {
    success
    message
  }
}
    `;
export type RemoveGrowerUserMutationFn = Apollo.MutationFunction<RemoveGrowerUserMutation, RemoveGrowerUserMutationVariables>;

/**
 * __useRemoveGrowerUserMutation__
 *
 * To run a mutation, you first call `useRemoveGrowerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGrowerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGrowerUserMutation, { data, loading, error }] = useRemoveGrowerUserMutation({
 *   variables: {
 *      grower: // value for 'grower'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveGrowerUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGrowerUserMutation, RemoveGrowerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGrowerUserMutation, RemoveGrowerUserMutationVariables>(RemoveGrowerUserDocument, options);
      }
export type RemoveGrowerUserMutationHookResult = ReturnType<typeof useRemoveGrowerUserMutation>;
export type RemoveGrowerUserMutationResult = Apollo.MutationResult<RemoveGrowerUserMutation>;
export type RemoveGrowerUserMutationOptions = Apollo.BaseMutationOptions<RemoveGrowerUserMutation, RemoveGrowerUserMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation sendResetPasswordEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    success
    errors
  }
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const ResetPasswordThroughEmailCodeDocument = gql`
    mutation resetPasswordThroughEmailCode($token: String!, $newPassword: String!) {
  passwordReset(
    token: $token
    newPassword1: $newPassword
    newPassword2: $newPassword
  ) {
    success
    errors
  }
}
    `;
export type ResetPasswordThroughEmailCodeMutationFn = Apollo.MutationFunction<ResetPasswordThroughEmailCodeMutation, ResetPasswordThroughEmailCodeMutationVariables>;

/**
 * __useResetPasswordThroughEmailCodeMutation__
 *
 * To run a mutation, you first call `useResetPasswordThroughEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordThroughEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordThroughEmailCodeMutation, { data, loading, error }] = useResetPasswordThroughEmailCodeMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordThroughEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordThroughEmailCodeMutation, ResetPasswordThroughEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordThroughEmailCodeMutation, ResetPasswordThroughEmailCodeMutationVariables>(ResetPasswordThroughEmailCodeDocument, options);
      }
export type ResetPasswordThroughEmailCodeMutationHookResult = ReturnType<typeof useResetPasswordThroughEmailCodeMutation>;
export type ResetPasswordThroughEmailCodeMutationResult = Apollo.MutationResult<ResetPasswordThroughEmailCodeMutation>;
export type ResetPasswordThroughEmailCodeMutationOptions = Apollo.BaseMutationOptions<ResetPasswordThroughEmailCodeMutation, ResetPasswordThroughEmailCodeMutationVariables>;
export const ResetPasswordThroughOldPasswordDocument = gql`
    mutation resetPasswordThroughOldPassword($oldPass: String!, $newPass: String!) {
  passwordChange(
    oldPassword: $oldPass
    newPassword1: $newPass
    newPassword2: $newPass
  ) {
    success
    token
    errors
  }
}
    `;
export type ResetPasswordThroughOldPasswordMutationFn = Apollo.MutationFunction<ResetPasswordThroughOldPasswordMutation, ResetPasswordThroughOldPasswordMutationVariables>;

/**
 * __useResetPasswordThroughOldPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordThroughOldPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordThroughOldPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordThroughOldPasswordMutation, { data, loading, error }] = useResetPasswordThroughOldPasswordMutation({
 *   variables: {
 *      oldPass: // value for 'oldPass'
 *      newPass: // value for 'newPass'
 *   },
 * });
 */
export function useResetPasswordThroughOldPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordThroughOldPasswordMutation, ResetPasswordThroughOldPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordThroughOldPasswordMutation, ResetPasswordThroughOldPasswordMutationVariables>(ResetPasswordThroughOldPasswordDocument, options);
      }
export type ResetPasswordThroughOldPasswordMutationHookResult = ReturnType<typeof useResetPasswordThroughOldPasswordMutation>;
export type ResetPasswordThroughOldPasswordMutationResult = Apollo.MutationResult<ResetPasswordThroughOldPasswordMutation>;
export type ResetPasswordThroughOldPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordThroughOldPasswordMutation, ResetPasswordThroughOldPasswordMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($token: String!) {
  verifyToken(token: $token) {
    isStaff
    isFieldStaff
    success
    errors
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const UserDeleteDocument = gql`
    mutation userDelete($userid: ID!) {
  userDelete(userid: $userid) {
    success
    message
  }
}
    `;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      userid: // value for 'userid'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserReinviteDocument = gql`
    mutation userReinvite($userid: ID!) {
  userReinvite(userid: $userid) {
    success
    message
  }
}
    `;
export type UserReinviteMutationFn = Apollo.MutationFunction<UserReinviteMutation, UserReinviteMutationVariables>;

/**
 * __useUserReinviteMutation__
 *
 * To run a mutation, you first call `useUserReinviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserReinviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userReinviteMutation, { data, loading, error }] = useUserReinviteMutation({
 *   variables: {
 *      userid: // value for 'userid'
 *   },
 * });
 */
export function useUserReinviteMutation(baseOptions?: Apollo.MutationHookOptions<UserReinviteMutation, UserReinviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserReinviteMutation, UserReinviteMutationVariables>(UserReinviteDocument, options);
      }
export type UserReinviteMutationHookResult = ReturnType<typeof useUserReinviteMutation>;
export type UserReinviteMutationResult = Apollo.MutationResult<UserReinviteMutation>;
export type UserReinviteMutationOptions = Apollo.BaseMutationOptions<UserReinviteMutation, UserReinviteMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($userId: ID!, $nameFirst: String, $nameLast: String, $email: String, $userType: ProfileTypeCreate) {
  profileTargetUpdate(
    userId: $userId
    nameFirst: $nameFirst
    nameLast: $nameLast
    email: $email
    userType: $userType
  ) {
    success
    message
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      nameFirst: // value for 'nameFirst'
 *      nameLast: // value for 'nameLast'
 *      email: // value for 'email'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const TokenDeleteDocument = gql`
    mutation tokenDelete($id: ID!) {
  deleteToken(id: $id) {
    success
    message
  }
}
    `;
export type TokenDeleteMutationFn = Apollo.MutationFunction<TokenDeleteMutation, TokenDeleteMutationVariables>;

/**
 * __useTokenDeleteMutation__
 *
 * To run a mutation, you first call `useTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenDeleteMutation, { data, loading, error }] = useTokenDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTokenDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TokenDeleteMutation, TokenDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenDeleteMutation, TokenDeleteMutationVariables>(TokenDeleteDocument, options);
      }
export type TokenDeleteMutationHookResult = ReturnType<typeof useTokenDeleteMutation>;
export type TokenDeleteMutationResult = Apollo.MutationResult<TokenDeleteMutation>;
export type TokenDeleteMutationOptions = Apollo.BaseMutationOptions<TokenDeleteMutation, TokenDeleteMutationVariables>;
export const TokenAddDocument = gql`
    mutation tokenAdd($name: String!, $token: String!) {
  addToken(name: $name, token: $token) {
    success
    message
  }
}
    `;
export type TokenAddMutationFn = Apollo.MutationFunction<TokenAddMutation, TokenAddMutationVariables>;

/**
 * __useTokenAddMutation__
 *
 * To run a mutation, you first call `useTokenAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAddMutation, { data, loading, error }] = useTokenAddMutation({
 *   variables: {
 *      name: // value for 'name'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTokenAddMutation(baseOptions?: Apollo.MutationHookOptions<TokenAddMutation, TokenAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenAddMutation, TokenAddMutationVariables>(TokenAddDocument, options);
      }
export type TokenAddMutationHookResult = ReturnType<typeof useTokenAddMutation>;
export type TokenAddMutationResult = Apollo.MutationResult<TokenAddMutation>;
export type TokenAddMutationOptions = Apollo.BaseMutationOptions<TokenAddMutation, TokenAddMutationVariables>;
export const GetAllFilesDocument = gql`
    query getAllFiles($fileType: ID, $search: String, $block: ID, $grower: ID, $cropYear: Int, $method: String, $variety: ID, $sortBy: String, $sortDirection: String, $offset: Int = 0, $limit: Int = 10) {
  allFiles(
    fileType: $fileType
    block: $block
    grower: $grower
    cropYear: $cropYear
    method: $method
    variety: $variety
    search: $search
    sort: $sortBy
    order: $sortDirection
    limit: $limit
    offset: $offset
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    files {
      ...allFileFields
    }
  }
}
    ${AllFileFieldsFragmentDoc}`;

/**
 * __useGetAllFilesQuery__
 *
 * To run a query within a React component, call `useGetAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFilesQuery({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      search: // value for 'search'
 *      block: // value for 'block'
 *      grower: // value for 'grower'
 *      cropYear: // value for 'cropYear'
 *      method: // value for 'method'
 *      variety: // value for 'variety'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllFilesQuery, GetAllFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFilesQuery, GetAllFilesQueryVariables>(GetAllFilesDocument, options);
      }
export function useGetAllFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFilesQuery, GetAllFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFilesQuery, GetAllFilesQueryVariables>(GetAllFilesDocument, options);
        }
export type GetAllFilesQueryHookResult = ReturnType<typeof useGetAllFilesQuery>;
export type GetAllFilesLazyQueryHookResult = ReturnType<typeof useGetAllFilesLazyQuery>;
export type GetAllFilesQueryResult = Apollo.QueryResult<GetAllFilesQuery, GetAllFilesQueryVariables>;
export const GetAllVarietiesDocument = gql`
    query getAllVarieties($search: String, $sortBy: String = "id", $sortDirection: String = "DESC", $offset: Int = 0, $limit: Int = 10) {
  allVarieties(
    search: $search
    sort: $sortBy
    order: $sortDirection
    offset: $offset
    limit: $limit
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    varieties {
      name
      id
    }
  }
}
    `;

/**
 * __useGetAllVarietiesQuery__
 *
 * To run a query within a React component, call `useGetAllVarietiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVarietiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVarietiesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllVarietiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVarietiesQuery, GetAllVarietiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVarietiesQuery, GetAllVarietiesQueryVariables>(GetAllVarietiesDocument, options);
      }
export function useGetAllVarietiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVarietiesQuery, GetAllVarietiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVarietiesQuery, GetAllVarietiesQueryVariables>(GetAllVarietiesDocument, options);
        }
export type GetAllVarietiesQueryHookResult = ReturnType<typeof useGetAllVarietiesQuery>;
export type GetAllVarietiesLazyQueryHookResult = ReturnType<typeof useGetAllVarietiesLazyQuery>;
export type GetAllVarietiesQueryResult = Apollo.QueryResult<GetAllVarietiesQuery, GetAllVarietiesQueryVariables>;
export const GetAllStaffUsersDocument = gql`
    query getAllStaffUsers($limit: Int = 50) {
  allStaff(limit: $limit) {
    users {
      profile {
        nameFirst
        nameLast
        isFieldStaff
      }
      id
      email
    }
  }
}
    `;

/**
 * __useGetAllStaffUsersQuery__
 *
 * To run a query within a React component, call `useGetAllStaffUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStaffUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStaffUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllStaffUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>(GetAllStaffUsersDocument, options);
      }
export function useGetAllStaffUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>(GetAllStaffUsersDocument, options);
        }
export type GetAllStaffUsersQueryHookResult = ReturnType<typeof useGetAllStaffUsersQuery>;
export type GetAllStaffUsersLazyQueryHookResult = ReturnType<typeof useGetAllStaffUsersLazyQuery>;
export type GetAllStaffUsersQueryResult = Apollo.QueryResult<GetAllStaffUsersQuery, GetAllStaffUsersQueryVariables>;
export const GetAllInvitedStaffUsersDocument = gql`
    query getAllInvitedStaffUsers($limit: Int = 50) {
  allInvitedStaff(limit: $limit) {
    invited {
      id
      isFieldStaff
      email
    }
  }
}
    `;

/**
 * __useGetAllInvitedStaffUsersQuery__
 *
 * To run a query within a React component, call `useGetAllInvitedStaffUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvitedStaffUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvitedStaffUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllInvitedStaffUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvitedStaffUsersQuery, GetAllInvitedStaffUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvitedStaffUsersQuery, GetAllInvitedStaffUsersQueryVariables>(GetAllInvitedStaffUsersDocument, options);
      }
export function useGetAllInvitedStaffUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvitedStaffUsersQuery, GetAllInvitedStaffUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvitedStaffUsersQuery, GetAllInvitedStaffUsersQueryVariables>(GetAllInvitedStaffUsersDocument, options);
        }
export type GetAllInvitedStaffUsersQueryHookResult = ReturnType<typeof useGetAllInvitedStaffUsersQuery>;
export type GetAllInvitedStaffUsersLazyQueryHookResult = ReturnType<typeof useGetAllInvitedStaffUsersLazyQuery>;
export type GetAllInvitedStaffUsersQueryResult = Apollo.QueryResult<GetAllInvitedStaffUsersQuery, GetAllInvitedStaffUsersQueryVariables>;
export const GetAllGrowersDocument = gql`
    query getAllGrowers($graphMachine: String, $search: String, $sortBy: String = "internal_id", $sortDirection: String = "ASC", $offset: Int = 0, $limit: Int = 10) {
  allGrowers(
    graphMachine: $graphMachine
    search: $search
    sort: $sortBy
    order: $sortDirection
    offset: $offset
    limit: $limit
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    growers {
      name: formattedName
      id
    }
  }
}
    `;

/**
 * __useGetAllGrowersQuery__
 *
 * To run a query within a React component, call `useGetAllGrowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGrowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGrowersQuery({
 *   variables: {
 *      graphMachine: // value for 'graphMachine'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllGrowersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGrowersQuery, GetAllGrowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGrowersQuery, GetAllGrowersQueryVariables>(GetAllGrowersDocument, options);
      }
export function useGetAllGrowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGrowersQuery, GetAllGrowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGrowersQuery, GetAllGrowersQueryVariables>(GetAllGrowersDocument, options);
        }
export type GetAllGrowersQueryHookResult = ReturnType<typeof useGetAllGrowersQuery>;
export type GetAllGrowersLazyQueryHookResult = ReturnType<typeof useGetAllGrowersLazyQuery>;
export type GetAllGrowersQueryResult = Apollo.QueryResult<GetAllGrowersQuery, GetAllGrowersQueryVariables>;
export const GetAllGrowersWithUsersDocument = gql`
    query getAllGrowersWithUsers($search: String, $sortBy: String = "internal_id", $sortDirection: String = "ASC", $offset: Int = 0, $limit: Int = 50) {
  allGrowers(
    search: $search
    sort: $sortBy
    order: $sortDirection
    offset: $offset
    limit: $limit
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    growers {
      name: formattedName
      id
      users {
        id
        nameFirst
        nameLast
      }
    }
  }
}
    `;

/**
 * __useGetAllGrowersWithUsersQuery__
 *
 * To run a query within a React component, call `useGetAllGrowersWithUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGrowersWithUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGrowersWithUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllGrowersWithUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGrowersWithUsersQuery, GetAllGrowersWithUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGrowersWithUsersQuery, GetAllGrowersWithUsersQueryVariables>(GetAllGrowersWithUsersDocument, options);
      }
export function useGetAllGrowersWithUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGrowersWithUsersQuery, GetAllGrowersWithUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGrowersWithUsersQuery, GetAllGrowersWithUsersQueryVariables>(GetAllGrowersWithUsersDocument, options);
        }
export type GetAllGrowersWithUsersQueryHookResult = ReturnType<typeof useGetAllGrowersWithUsersQuery>;
export type GetAllGrowersWithUsersLazyQueryHookResult = ReturnType<typeof useGetAllGrowersWithUsersLazyQuery>;
export type GetAllGrowersWithUsersQueryResult = Apollo.QueryResult<GetAllGrowersWithUsersQuery, GetAllGrowersWithUsersQueryVariables>;
export const GetAllGrowerUsersDocument = gql`
    query getAllGrowerUsers($growerId: ID!) {
  grower(id: $growerId) {
    activeUsers: users {
      user {
        id
        email
      }
    }
    invitedUsers: invited {
      id
      email
      accessReturns
    }
  }
}
    `;

/**
 * __useGetAllGrowerUsersQuery__
 *
 * To run a query within a React component, call `useGetAllGrowerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGrowerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGrowerUsersQuery({
 *   variables: {
 *      growerId: // value for 'growerId'
 *   },
 * });
 */
export function useGetAllGrowerUsersQuery(baseOptions: Apollo.QueryHookOptions<GetAllGrowerUsersQuery, GetAllGrowerUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGrowerUsersQuery, GetAllGrowerUsersQueryVariables>(GetAllGrowerUsersDocument, options);
      }
export function useGetAllGrowerUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGrowerUsersQuery, GetAllGrowerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGrowerUsersQuery, GetAllGrowerUsersQueryVariables>(GetAllGrowerUsersDocument, options);
        }
export type GetAllGrowerUsersQueryHookResult = ReturnType<typeof useGetAllGrowerUsersQuery>;
export type GetAllGrowerUsersLazyQueryHookResult = ReturnType<typeof useGetAllGrowerUsersLazyQuery>;
export type GetAllGrowerUsersQueryResult = Apollo.QueryResult<GetAllGrowerUsersQuery, GetAllGrowerUsersQueryVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers($search: String, $userType: ProfileType, $offset: Int = 0, $limit: Int = 50) {
  allProfiles(
    search: $search
    userType: $userType
    offset: $offset
    limit: $limit
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    users {
      id
      nameFirst
      nameLast
      user {
        id
        email
        lastLogin
      }
      events {
        edges {
          node {
            eventType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      userType: // value for 'userType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetAllInvitedDocument = gql`
    query getAllInvited($offset: Int = 0, $limit: Int = 50) {
  allInvited(offset: $offset, limit: $limit) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    invited {
      id
      email
    }
  }
}
    `;

/**
 * __useGetAllInvitedQuery__
 *
 * To run a query within a React component, call `useGetAllInvitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvitedQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllInvitedQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvitedQuery, GetAllInvitedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvitedQuery, GetAllInvitedQueryVariables>(GetAllInvitedDocument, options);
      }
export function useGetAllInvitedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvitedQuery, GetAllInvitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvitedQuery, GetAllInvitedQueryVariables>(GetAllInvitedDocument, options);
        }
export type GetAllInvitedQueryHookResult = ReturnType<typeof useGetAllInvitedQuery>;
export type GetAllInvitedLazyQueryHookResult = ReturnType<typeof useGetAllInvitedLazyQuery>;
export type GetAllInvitedQueryResult = Apollo.QueryResult<GetAllInvitedQuery, GetAllInvitedQueryVariables>;
export const GetAllBlocksDocument = gql`
    query getAllBlocks($graphMachine: String, $grower: ID, $poolId: ID, $search: String, $sortBy: String = "name", $sortDirection: String = "ASC", $offset: Int = 0, $limit: Int = 10) {
  allBlocks(
    graphMachine: $graphMachine
    grower: $grower
    poolId: $poolId
    search: $search
    sort: $sortBy
    order: $sortDirection
    offset: $offset
    limit: $limit
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    blocks {
      name
      id
    }
  }
}
    `;

/**
 * __useGetAllBlocksQuery__
 *
 * To run a query within a React component, call `useGetAllBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlocksQuery({
 *   variables: {
 *      graphMachine: // value for 'graphMachine'
 *      grower: // value for 'grower'
 *      poolId: // value for 'poolId'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllBlocksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBlocksQuery, GetAllBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBlocksQuery, GetAllBlocksQueryVariables>(GetAllBlocksDocument, options);
      }
export function useGetAllBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBlocksQuery, GetAllBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBlocksQuery, GetAllBlocksQueryVariables>(GetAllBlocksDocument, options);
        }
export type GetAllBlocksQueryHookResult = ReturnType<typeof useGetAllBlocksQuery>;
export type GetAllBlocksLazyQueryHookResult = ReturnType<typeof useGetAllBlocksLazyQuery>;
export type GetAllBlocksQueryResult = Apollo.QueryResult<GetAllBlocksQuery, GetAllBlocksQueryVariables>;
export const GetAllPoolsDocument = gql`
    query getAllPools($graphMachine: String, $block: ID, $growerId: ID, $search: String, $sortBy: String = "value", $sortDirection: String = "ASC", $offset: Int = 0, $limit: Int = 10) {
  allPools(
    graphMachine: $graphMachine
    block: $block
    growerId: $growerId
    search: $search
    sort: $sortBy
    order: $sortDirection
    offset: $offset
    limit: $limit
  ) {
    pageInfo {
      totalCount
      totalPages
      pageLimit
    }
    pools {
      value
      id
      closed
    }
  }
}
    `;

/**
 * __useGetAllPoolsQuery__
 *
 * To run a query within a React component, call `useGetAllPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoolsQuery({
 *   variables: {
 *      graphMachine: // value for 'graphMachine'
 *      block: // value for 'block'
 *      growerId: // value for 'growerId'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllPoolsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPoolsQuery, GetAllPoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPoolsQuery, GetAllPoolsQueryVariables>(GetAllPoolsDocument, options);
      }
export function useGetAllPoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPoolsQuery, GetAllPoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPoolsQuery, GetAllPoolsQueryVariables>(GetAllPoolsDocument, options);
        }
export type GetAllPoolsQueryHookResult = ReturnType<typeof useGetAllPoolsQuery>;
export type GetAllPoolsLazyQueryHookResult = ReturnType<typeof useGetAllPoolsLazyQuery>;
export type GetAllPoolsQueryResult = Apollo.QueryResult<GetAllPoolsQuery, GetAllPoolsQueryVariables>;
export const MyProfileDocument = gql`
    query myProfile {
  myProfile {
    email
    nameLast
    nameFirst
    phone
    receiveSms
    receiveEmail
    accessPackout
    accessReturns
    growers {
      ...allGrowersFields
    }
  }
}
    ${AllGrowersFieldsFragmentDoc}`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
      }
export function useMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
        }
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const GetGlobalGrowerMessageDocument = gql`
    query getGlobalGrowerMessage {
  setting(id: "U2V0dGluZ3M6MQ==") {
    message: value
  }
}
    `;

/**
 * __useGetGlobalGrowerMessageQuery__
 *
 * To run a query within a React component, call `useGetGlobalGrowerMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalGrowerMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalGrowerMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalGrowerMessageQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalGrowerMessageQuery, GetGlobalGrowerMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalGrowerMessageQuery, GetGlobalGrowerMessageQueryVariables>(GetGlobalGrowerMessageDocument, options);
      }
export function useGetGlobalGrowerMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalGrowerMessageQuery, GetGlobalGrowerMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalGrowerMessageQuery, GetGlobalGrowerMessageQueryVariables>(GetGlobalGrowerMessageDocument, options);
        }
export type GetGlobalGrowerMessageQueryHookResult = ReturnType<typeof useGetGlobalGrowerMessageQuery>;
export type GetGlobalGrowerMessageLazyQueryHookResult = ReturnType<typeof useGetGlobalGrowerMessageLazyQuery>;
export type GetGlobalGrowerMessageQueryResult = Apollo.QueryResult<GetGlobalGrowerMessageQuery, GetGlobalGrowerMessageQueryVariables>;
export const GetGrowerDocument = gql`
    query getGrower($id: ID!) {
  grower(id: $id) {
    ...growerFields
  }
}
    ${GrowerFieldsFragmentDoc}`;

/**
 * __useGetGrowerQuery__
 *
 * To run a query within a React component, call `useGetGrowerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrowerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrowerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGrowerQuery(baseOptions: Apollo.QueryHookOptions<GetGrowerQuery, GetGrowerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrowerQuery, GetGrowerQueryVariables>(GetGrowerDocument, options);
      }
export function useGetGrowerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGrowerQuery, GetGrowerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrowerQuery, GetGrowerQueryVariables>(GetGrowerDocument, options);
        }
export type GetGrowerQueryHookResult = ReturnType<typeof useGetGrowerQuery>;
export type GetGrowerLazyQueryHookResult = ReturnType<typeof useGetGrowerLazyQuery>;
export type GetGrowerQueryResult = Apollo.QueryResult<GetGrowerQuery, GetGrowerQueryVariables>;
export const GetGraphDocument = gql`
    query getGraph($graphMachine: String!, $growerId: ID!, $poolId: ID, $blockId: ID) {
  graph(
    graphMachine: $graphMachine
    grower: $growerId
    pool: $poolId
    block: $blockId
  ) {
    created
    modified
    data
    stats
    pool {
      id
      value
      closed
    }
    block {
      id
      name
    }
    graph {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGraphQuery__
 *
 * To run a query within a React component, call `useGetGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphQuery({
 *   variables: {
 *      graphMachine: // value for 'graphMachine'
 *      growerId: // value for 'growerId'
 *      poolId: // value for 'poolId'
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useGetGraphQuery(baseOptions: Apollo.QueryHookOptions<GetGraphQuery, GetGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphQuery, GetGraphQueryVariables>(GetGraphDocument, options);
      }
export function useGetGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphQuery, GetGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphQuery, GetGraphQueryVariables>(GetGraphDocument, options);
        }
export type GetGraphQueryHookResult = ReturnType<typeof useGetGraphQuery>;
export type GetGraphLazyQueryHookResult = ReturnType<typeof useGetGraphLazyQuery>;
export type GetGraphQueryResult = Apollo.QueryResult<GetGraphQuery, GetGraphQueryVariables>;
export const GetCropProductionDataDocument = gql`
    query getCropProductionData($growerId: ID!) {
  cropProduction: cropYear(growerId: $growerId) {
    cherry
    apple
    pear
  }
}
    `;

/**
 * __useGetCropProductionDataQuery__
 *
 * To run a query within a React component, call `useGetCropProductionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCropProductionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCropProductionDataQuery({
 *   variables: {
 *      growerId: // value for 'growerId'
 *   },
 * });
 */
export function useGetCropProductionDataQuery(baseOptions: Apollo.QueryHookOptions<GetCropProductionDataQuery, GetCropProductionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCropProductionDataQuery, GetCropProductionDataQueryVariables>(GetCropProductionDataDocument, options);
      }
export function useGetCropProductionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCropProductionDataQuery, GetCropProductionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCropProductionDataQuery, GetCropProductionDataQueryVariables>(GetCropProductionDataDocument, options);
        }
export type GetCropProductionDataQueryHookResult = ReturnType<typeof useGetCropProductionDataQuery>;
export type GetCropProductionDataLazyQueryHookResult = ReturnType<typeof useGetCropProductionDataLazyQuery>;
export type GetCropProductionDataQueryResult = Apollo.QueryResult<GetCropProductionDataQuery, GetCropProductionDataQueryVariables>;
export const GetProfileDocument = gql`
    query getProfile($profile: ID!) {
  profile(profile: $profile) {
    userId
    email
    phone
    nameFirst
    nameLast
    isFieldStaff
    userType
    growerSettings {
      grower {
        ...growerFields
      }
      accessReturns
      accessPackout
    }
    events {
      created
      eventType
    }
  }
}
    ${GrowerFieldsFragmentDoc}`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetAllTokensDocument = gql`
    query getAllTokens($offset: Int = 0) {
  allTokens(offset: $offset) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        token
        valid
      }
    }
  }
}
    `;

/**
 * __useGetAllTokensQuery__
 *
 * To run a query within a React component, call `useGetAllTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTokensQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTokensQuery, GetAllTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTokensQuery, GetAllTokensQueryVariables>(GetAllTokensDocument, options);
      }
export function useGetAllTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTokensQuery, GetAllTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTokensQuery, GetAllTokensQueryVariables>(GetAllTokensDocument, options);
        }
export type GetAllTokensQueryHookResult = ReturnType<typeof useGetAllTokensQuery>;
export type GetAllTokensLazyQueryHookResult = ReturnType<typeof useGetAllTokensLazyQuery>;
export type GetAllTokensQueryResult = Apollo.QueryResult<GetAllTokensQuery, GetAllTokensQueryVariables>;