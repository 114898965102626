import * as React from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { User } from "../config/user";
import { AuthContext } from "../providers/AuthProvider";

type Props = {
    isPublic: boolean;
    scope?: User[]
} & RouteProps;

const EnhancedRoute: React.FC<Props> = ({ isPublic, scope, ...props }: Props) => {
    const { push } = useHistory();
    const { authenticated } = React.useContext(AuthContext);
    const prevLocation = useLocation();

    React.useEffect(() => {
        if(isPublic && authenticated)
            push("/");
        // Wait if authenticated is null
        if(!isPublic && authenticated === false) {
            if (prevLocation.pathname.length > 0 && prevLocation.pathname !== "/login" && prevLocation.pathname !== "/") {
                push(`/login?redirect=${prevLocation.pathname}`);
            } else {
                push("/login");
            }
        }
    }, [isPublic, authenticated, push]);

    React.useEffect(() => {
        if(authenticated && scope && !scope.includes(window.env.userType as User)) {
            push("/");
        }
    }, [scope, authenticated, push]);

    return authenticated === null ? null: <Route {...props} />;
}

export default EnhancedRoute;
