import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";

const modifiedTheme = {
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px",
        background: "#FFF"
      }
    },
    MuiButton: {
      contained: {
        "&:hover": "unset",
      },
      containedPrimary: {
        backgroundColor: "#B20A36",
        color: "#FFF",
        "&:hover": "unset"
      },
      containedSecondary: {
        backgroundColor: "#FFF",
        color: "#B20A36",
        border: "3px solid #B20A36",
        "&:hover": "unset"
      },
      root: {
        borderRadius: "30px",
        minWidth: "160px",
        minHeight: "50px",
        "&:hover": "unset",
      },
    }
  },
  typography: {
    fontFamily: "'Roboto Slab'",
    body1: {
      fontSize: "16px"
    },
    body2: {
      fontSize: "12px",
    },
    h4: {
      fontSize: "34px",
      fontFamily: "Josefin Sans",
      textTransform: "uppercase"
    },
    h5: {
      fontSize: "36px",
      fontFamily: "Josefin Sans",
      textTransform: "uppercase"
    },
    h6: {
      fontFamily: "Josefin Sans",
      textTransform: "uppercase"
    },
    subtitle1: {
      fontFamily: "Josefin Sans",
      color: "rgba(28,53,50,0.7)",
      fontSize: "20px",
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    subtitle2: {
      fontFamily: "Josefin Sans",
      color: "#1C3532",
      fontSize: "18px",
      fontWeight: 300
    }
  },
  palette: {
    text: {
      primary: "rgb(28,53,50,0.7)"
    },
    primary: {
      main: "#B20A36",
    }
  }
} as ThemeOptions;

export default createMuiTheme(modifiedTheme);
