import { User } from "./user";
import proxifyEnvironment from "../utilities/environment";
import bindToWindow from "../utilities/windowBinder";

const env = {
    token: "{{auth_token}}",
    graph: "{{GQL_SCHEMA_PATH}}",
    growerCsv: "http://api.sagefruit.com/users?token={{auth_token}}",
    userType: () =>
        localStorage.getItem("user-type") === "staff" ? 
            User.STAFF: 
            User.GROWER
};

const proxifiedEnv = proxifyEnvironment(env);

export type Environment = typeof proxifiedEnv;

bindToWindow("env", proxifiedEnv);