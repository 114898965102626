import { difference } from "lodash";
import { User } from "../config/user";
/**
 * This utility proxifies environment objects such that
 * placeholders get replaced with the relevant stage related
 * values.
 */

// This tells the proxy handler function that the placeholder is
// a user dependent variable.
type UserParameter = Record<User, string>;

type FunctionParameter = () => string;

// This replaces the UserParameter types in the environment object with
// new specified type.
type ReplaceKeyType<T extends Record<string, unknown>, OldType, NewType> = {
  [key in keyof T]: T[key] extends OldType ? NewType : T[key];
};

// The type that is returned after applying the handler function.
type Proxify<T extends Record<string, unknown>> = {
  [key in keyof T]: T[key] extends UserParameter
    ? ReplaceKeyType<T, UserParameter, string>[key]
    : T[key] extends Record<string, unknown>
    ? Proxify<T[key]>
    : T[key] extends FunctionParameter
    ? string
    : T[key];
};



// Handles get calls to the object.
// eslint-disable-next-line
const proxify = (context: Record<string, string> = {}) => {
  const getHandler = (target: any, prop: any): any => {
    const user = (context.user || "Grower") as User;
    if (target[prop] && typeof target[prop] === "object") {
      // Values is an object. It could be UserParameter or a child environment object.
      if (
        difference(Object.keys(target[prop]), [User.STAFF, User.GROWER])
          .length
      )
        return new Proxy(target[prop], { get: getHandler }); // Child environment object so we recurse
      return target[prop][user]; // UserParameter, so we extract the appropriate value.
    }
    if (typeof target[prop] === "string") {
      // Value is a string. Search for the {{}} pattern and replace it with
      // either the env or the current object.
      return target[prop].replace(/\{\{(.*?)\}\}/g, (...args: string[]) => {
        const group = args[1];
        return (
          process.env[group] ||
          process.env[`REACT_APP_${group}`] ||
          context[group] ||
          localStorage.getItem(group) ||
          sessionStorage.getItem(group) ||
          getHandler(target, group) ||
          ""
        );
      });
    }

    if (
      target[prop] &&
      target[prop].constructor &&
      target[prop].call &&
      target[prop].apply
    ) {
      // Value is a function.
      return target[prop]().replace(/\{\{(.*?)\}\}/g, (...args: string[]) => {
        const group = args[1];
        return (
          process.env[group] ||
          process.env[`REACT_APP_${group}`] ||
          context[group] ||
          getHandler(target, group) ||
          ""
        );
      });
    }
    // Just return the value.
    return target[prop];
  };
  return getHandler;
};

// Sad that the Proxy Object by default has the same output type as the input type.
// https://github.com/Microsoft/TypeScript/issues/20846
const proxy = <T extends Record<string, unknown>>(
  env: T,
  context?: Record<string, string>,
) => {
  const getHandler = proxify(context);
  return new Proxy(env, { get: getHandler }) as Proxify<T>;
};

export default proxy;