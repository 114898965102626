import * as React from "react";
import { GrowerBarContext, GrowerType } from "./GrowerBarProvider";
import type { BlockType } from '../components/search/BlockSearch';
import type { PoolType } from '../components/search/PoolSearch';
import { User } from "../config/user";

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderState = {
  grower: GrowerType | null,
  setGrower: React.Dispatch<React.SetStateAction<GrowerType | null>>,
  block: BlockType | null,
  setBlock: React.Dispatch<React.SetStateAction<BlockType | null>>,
  pool: PoolType | null,
  setPool: React.Dispatch<React.SetStateAction<PoolType | null>>,
  shouldAutoselect: boolean,
  setShouldAutoselect: React.Dispatch<React.SetStateAction<boolean>>,
};

const GraphFiltersContext = React.createContext<ProviderState>({
  grower: null,
  setGrower: () => {},
  block: null,
  setBlock: () => {},
  pool: null,
  setPool: () => {},
  shouldAutoselect: true,
  setShouldAutoselect: () => {}
});

const GraphGrowerProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { globalGrower, setGlobalGrower } = React.useContext(GrowerBarContext);
  const [ localGrower, setLocalGrower ] = React.useState<GrowerType | null>(null);
  const [ block, setBlock ] = React.useState<BlockType | null>(null);
  const [ pool, setPool ] = React.useState<PoolType | null>(null);
  const [ shouldAutoselect, setShouldAutoselect ] = React.useState<boolean>(true);
  
  const grower = window.env.userType === User.GROWER ? globalGrower: localGrower;
  const setGrower = window.env.userType === User.GROWER ? setGlobalGrower: setLocalGrower;
  
  React.useEffect(() => {
    setBlock(prevBlock => {
      if(prevBlock?.id === block?.id) {
        setPool(null);
        return null;
      }
      return prevBlock;
    });
  }, [grower?.id]);
  
  return (
    <GraphFiltersContext.Provider
      value={{
        grower,
        setGrower,
        block,
        setBlock,
        pool,
        setPool,
        shouldAutoselect,
        setShouldAutoselect
      }}
    >
      {children}
    </GraphFiltersContext.Provider>
  );
};

export default GraphGrowerProvider;

export { GraphFiltersContext };
