import ReactDOM from 'react-dom';
import { init as initSentry } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';

initSentry({
  dsn: "https://1b32d9d22b01437abe1632f4cd1552ef@o126739.ingest.sentry.io/5799933",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);