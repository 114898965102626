import * as React from "react";
import { AuthContext } from "./AuthProvider";
import { Grower, useGetAllGrowersQuery } from "../graphql";

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderState = {
  globalGrower: GrowerType | null,
  setGlobalGrower: React.Dispatch<React.SetStateAction<GrowerType | null>>
};

export type GrowerType = Pick<Grower, "id" | "name">;

const GrowerBarContext = React.createContext<ProviderState>({
  globalGrower: null,
  setGlobalGrower: () => {}
});

const GrowerBarProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { authenticated } = React.useContext(AuthContext);
  const savedGrower = localStorage.getItem("globalGrower");
  const [globalGrower, setGlobalGrower] = React.useState<GrowerType | null>(savedGrower ? JSON.parse(savedGrower): null);
  const { data } = useGetAllGrowersQuery();

  React.useEffect(() => {
    if(globalGrower !== null)
      localStorage.setItem("globalGrower", JSON.stringify(globalGrower));
  }, [globalGrower]);

  React.useEffect(() => {
    if(
      localStorage.getItem("user-type")
      && localStorage.getItem("user-type") !== "staff"
      && globalGrower === null 
      && data 
      && data.allGrowers.growers.length > 0 
      && !localStorage.getItem("globalGrower")
    )
      setGlobalGrower(data.allGrowers.growers[0]);
  }, [data, globalGrower]);

  React.useEffect(() => {
    if(!authenticated) {
      localStorage.removeItem("globalGrower");
      setGlobalGrower(null);
    }
  }, [authenticated]);

  return (
    <GrowerBarContext.Provider
      value={{
        globalGrower,
        setGlobalGrower 
      }}
    >
      {children}
    </GrowerBarContext.Provider>
  );
};

export default GrowerBarProvider;

export { GrowerBarContext };
