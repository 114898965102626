import * as React from "react";
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import AuthProvider from "./providers/AuthProvider";
import GrowerBarProvider from "./providers/GrowerBarProvider";
import "./config/environment";
import theme from "./config/theme";
import gqlClient from "./config/gql";
import routes from "./config/route";
import EnhancedRoute from "./components/EnhancedRoute";
import Loader from "./components/Loader";
import GraphFiltersProvider from "./providers/GraphFiltersProvider";

const App: React.FC = () => {

  React.useEffect(() => {
    Chart.registry.addPlugins(ChartDataLabels);
  }, []);

  return (
    <ApolloProvider client={gqlClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <GrowerBarProvider>
            <GraphFiltersProvider>
              <React.Suspense fallback={<Loader/>}>
                <BrowserRouter>
                  <Switch>
                    {
                      routes
                      .map(({ isPublic, path, scope, exact, component }, index) => {
                        return <EnhancedRoute 
                          isPublic={!!isPublic} 
                          exact={exact}
                          scope={scope} 
                          path={path} 
                          key={`${path}-${index}`} 
                          component={component} 
                        />
                      })
                    }
                  </Switch>
                </BrowserRouter>
              </React.Suspense>
            </GraphFiltersProvider>
          </GrowerBarProvider>
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
