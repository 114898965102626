import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { useVerifyTokenMutation, useAuthorizeMutation } from "../graphql";
import Loader from "../components/Loader";

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderState = {
  // Null shows a state in between true and false ie loading
  authenticated: boolean | null;
  login: (email: string, password: string, rememberMe?: boolean) => Promise<void>;
  logout: () => void;
};

const AuthContext = React.createContext<ProviderState>({
  authenticated: null,
  login: async () => {},
  logout: () => {}
});

const AuthProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const client = useApolloClient();
  const [authenticated, setAuthenticated] = React.useState<boolean | null>(null);
  const [verify, { loading, error, called }] = useVerifyTokenMutation();
  const [authorize] = useAuthorizeMutation();

  React.useEffect(() => {
    (async () => {
      const { data } = await verify({ variables: { token: window.env.token }});
      localStorage.setItem("user-type", data?.verifyToken?.isStaff && !(data?.verifyToken.isFieldStaff) ? "staff": "grower");
      setAuthenticated(!!data?.verifyToken?.success);
    })();
  }, [verify]);

  const login = async (email: string, password: string, rememberMe?: boolean): Promise<void> => {
    const storage = rememberMe ? localStorage: sessionStorage;
    try {
      const { data } = await authorize({ variables: { email, password }});
      if(!data) throw new Error("A network error occured");
      const { success, token, user, errors, isFieldStaff } = data.tokenAuth;
      if(errors || !success || !token) 
        throw new Error("Invalid username / password specified.");
      storage.setItem("auth_token", token);
      localStorage.setItem("user-type", user?.isStaff && !isFieldStaff ? "staff": "grower");
      setAuthenticated(true);
    }catch(e){
      storage.removeItem("auth_token");
      localStorage.removeItem("user-type");
      setAuthenticated(false);
      throw e;
    }
  }

  const logout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await client.clearStore();
    setAuthenticated(false);
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        login,
        logout
      }}
    >
      {(!loading && called && !error) ? children: <Loader/>}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export { AuthContext };
