import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => ({
    headers: {
        ...headers,
        "Authorization": `jwt ${window.env.token}`,
    }
}));

const link = createUploadLink({
    uri: window.env.graph,
}) as any;

const gqlClient = new ApolloClient({
    cache: new InMemoryCache({}),
    link: authLink.concat(link)
});

export default gqlClient;