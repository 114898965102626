import * as React from "react";
import { User } from "./user";

export type RouteItem = {
    path: string;
    component: React.FC;
    exact: boolean;
    isPublic?: boolean;
    scope?: User[];
};

const navigationItems: RouteItem[] = [
    {
        path: "/",
        component: React.lazy(() => import("../pages/dashboard")),
        exact: true,
    },
    {
        path: "/invite/:growerId?",
        component: React.lazy(() => import("../pages/Invite")),
        exact: true,
    },
    {
        path: "/inviteStaff",
        component: React.lazy(() => import("../pages/InviteStaff")),
        exact: true,
        scope: [User.STAFF]
    },
    {
        path: "/performance",
        component: React.lazy(() => import("../pages/Performance")),
        exact: true,
    },
    {
        path: "/performance/:id",
        component: React.lazy(() => import("../pages/Performance")),
        exact: true,
    },
    {
        path: "/files/:id",
        component: React.lazy(() => import("../pages/files")),
        exact: true,
    },
    {
        path: "/files/download/:id",
        component: React.lazy(() => import("../pages/files/Download")),
        exact: true,
    },
    {
        path: "/files/add/:id",
        component: React.lazy(() => import("../pages/files/Add")),
        exact: true,
    },
    {
        path: "/growers",
        component: React.lazy(() => import("../pages/growers")),
        exact: true,
        scope: [User.STAFF],
    },
    {
        path: "/growers-with-users",
        component: React.lazy(() => import("../pages/GrowersWithUsers")),
        exact: true,
        scope: [User.STAFF],
    },
    {
        path: "/grower/:id",
        component: React.lazy(() => import("../pages/growers/Grower")),
        exact: true,
        scope: [User.STAFF],
    },
    {
        path: "/users",
        component: React.lazy(() => import("../pages/users")),
        exact: true,
        scope: [User.STAFF],
    },
    {
        path: "/user/:id",
        component: React.lazy(() => import("../pages/users/EditUser")),
        exact: true,
        scope: [User.STAFF],
    },
    {
        path: "/users/invited",
        component: React.lazy(() => import("../pages/users/invited")),
        exact: true,
        scope: [User.STAFF],
    },
    {
        path: "/tools",
        exact: true,
        scope: [User.STAFF],
        component: React.lazy(() => import("../pages/Tools")),
    },
    {
        path: "/settings",
        exact: true,
        scope: [User.STAFF],
        component: React.lazy(() => import("../pages/Settings")),
    },
    {
        path: "/pats",
        exact: true,
        scope: [User.STAFF],
        component: React.lazy(() => import("../pages/Pats")),
    },
    {
        path: "/account",
        exact: true,
        component: React.lazy(() => import("../pages/Account")),
    },
    {
        path: "/change-password",
        exact: true,
        component: React.lazy(() => import("../pages/ChangePassword")),
    },
    {
        path: "/logout",
        exact: true,
        component: React.lazy(() => import("../pages/Logout")),
    },
    {
        path: "/forgot-password",
        exact: true,
        isPublic: true,
        component: React.lazy(() => import("../pages/ForgotPassword"))
    },
    {
        path: "/signup/:email/:token",
        exact: true,
        isPublic: true,
        component: React.lazy(() => import("../pages/Signup"))
    },
    {
        path: "/reset-password/:token?",
        exact: true,
        isPublic: true,
        component: React.lazy(() => import("../pages/ResetPassword"))
    },
    {
        path: "/login",
        exact: true,
        isPublic: true,
        component: React.lazy(() => import("../pages/Login"))
    }
];

export default navigationItems;