import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const Loader: React.FC = () => {
  return (
    <Box width="100%" minHeight="100vh" bgcolor="inherit" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress/>
    </Box>
  );
}

export default Loader;
